<template>
  <!--  将id:app的子盒子设置成100%100%, header content footer各自分开-->
  <div class="rootBox">
    <m-header />
    <div id="content" ref="content">
      <router-view />
      <m-footer />
    </div>
  </div>
</template>

<script>
import MHeader from 'components/header/header'
import MFooter from 'components/footer/footer'

export default {
  components: {
    MHeader,
    MFooter,
  },
}
</script>

<style lang="scss" scoped>
.rootBox {
  height: 100%;
}
#content {
  height: calc(100% - 72px);
  margin-top: 72px;
  overflow: auto;
  .wrapper {
    width: 1296px;
    margin: 0 auto;
  }
}
</style>
