<template>
  <div class="footer">
    <h2>
      联系我们
      <span>（工作日 09:30-18:30）</span>
    </h2>
    <p style="color: #fff; margin-top: 40px">
      联系地址：北京市海淀区上地西路6号北研大厦G座
    </p>
    <div class="qr_code">
      <img src="./QR.png" alt="qrcode" />
      <p>乐票客服</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.footer {
  position: relative;
  width: 100%;
  height: 224px;
  box-sizing: border-box;
  background: #333;
  padding-left: 280px;
  padding-top: 48px;
  //position: absolute;
  //bottom: 0;
  h2 {
    font-size: 24px;
    color: #fff;
    line-height: 28px;

    span {
      font-size: 16px;
      color: #fff;
      line-height: 26px;
    }
  }
  .qr_code {
    position: absolute;
    top: 30px;
    right: 280px;

    img {
      width: 120px;
    }

    p {
      margin-top: 10px;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
