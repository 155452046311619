<template>
  <div class="header">
    <router-link to="/home" class="left">
      <img src="./image/icon.png" alt="乐票闪贴" />
    </router-link>
    <el-menu
      :default-active="activeIndex"
      class="content"
      mode="horizontal"
      active-text-color="#2c68ff"
      router
    >
      <el-menu-item index="/bill-financing">票据融资</el-menu-item>
      <el-submenu index="2" v-if="$store.state.username">
        <template slot="title">用户中心</template>
        <el-menu-item index="/user/transaction-management">
          交易管理
        </el-menu-item>
        <el-menu-item index="/user/account-center">账户信息</el-menu-item>
        <el-menu-item index="/user/invoice-request">发票申请</el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="right">
      <div class="avatar-wrapper" v-if="$store.state.username">
        <p>您好，{{ $store.state.username }}</p>
        <el-dropdown
          style="display: flex; align-items: center"
          @command="handleCommand"
        >
          <img src="./image/avatar.png" alt="头像" style="cursor: pointer" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="password">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="login-wrapper" v-else>
        <router-link to="/login">登录</router-link>
        <router-link to="/register">注册</router-link>
      </div>
    </div>

    <!--  修改密码弹窗  -->
    <el-dialog
      title="修改密码"
      width="500px"
      append-to-body
      :visible.sync="dialog"
      :close-on-click-modal="false"
      @close="handleDialogClose"
    >
      <div class="form-wrapper">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="small"
          label-width="100px"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input type="password" v-model="form.oldPassword" />
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="form.newPassword" />
          </el-form-item>
          <el-form-item label="确认新密码" prop="repeatPassword">
            <el-input type="password" v-model="form.repeatPassword" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialog = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="handleChangePassword">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { logout, modifyPassword } from './api'

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.repeatPassword !== '') {
          this.$refs.form.validateField('repeatPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      activeIndex: '',
      dialog: false,
      form: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '输入框不能为空', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        repeatPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.initActiveIndex()
  },
  methods: {
    handleDialogClose() {
      this.$refs.form.resetFields()
    },
    async handleChangePassword() {
      try {
        const valid = await this.$refs.form.validate()

        if (valid) {
          const { oldPassword, newPassword } = this.form
          await modifyPassword({ oldPassword, newPassword })
          this.$message.success('修改成功')
          this.dialog = false
          await this._logout()
        }
      } catch (err) {
        console.error(err)
      }
    },
    initActiveIndex() {
      this.activeIndex = location.hash.substr(1)
    },
    handleCommand(command) {
      if (command === 'logout') {
        try {
          this.handleLogout()
        } catch (err) {
          console.error(err)
        }
      } else if (command === 'password') {
        this.dialog = true
      }
    },
    async handleLogout() {
      try {
        await logout()
        this.$message.success('退出成功')
        await this._logout()
      } catch (err) {
        console.error(err)
      }
    },
    async _logout() {
      this.$store.commit('clearAllStore')
      await this.$router.push('/login')
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  padding: 0 73px;
  line-height: 72px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;

  .left {
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex: 1;
    margin-left: 100px;

    .link-item {
      margin-right: 56px;
      a {
        font-size: 16px;
        color: #333;
        &:hover {
          color: #2c68ff;
        }
      }
    }
  }

  .right {
    .login-wrapper {
      a {
        font-size: 16px;
        color: #333;
        &:first-child {
          margin-right: 64px;
        }
        &:hover {
          color: #2c68ff;
        }
      }
    }

    .avatar-wrapper {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #333;
        margin-right: 9px;
      }
    }
  }
}
::v-deep.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #2c68ff;
  color: #fff;
}

::v-deep.el-menu-item {
  height: 72px;
  line-height: 72px;
  transition: none !important;
}

::v-deep.el-submenu {
  .el-submenu__title {
    height: 72px;
    line-height: 72px;
  }
}

::v-deep.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  border-bottom-color: transparent !important;
}

::v-deep.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
::v-deep.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
